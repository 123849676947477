<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">Request a Specialist</h1>
      <h3 class="container ls-page-header-subtitle">
        For those jobs in need of a carefully selected, verified Specialist. Our
        team of specialists are available to give their expert services at great
        value.
      </h3>
    </div>

    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-2 col-lg-3 col-md-2 col-12"></div>
        <div class="col-xl-8 col-lg-6 col-md-8 col-12">
          <router-view></router-view>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-2 col-12"></div>
      </div>
      <hr />
      <div class="row" v-if="auth.isAuthenticated">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link
            :to="{
              name: 'filtered_specialist_requests',
              params: { status: 'pending' },
            }"
          >
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ pendingRequests.length }}
                </div>
              </div>
              <div class="card-footer ls-center">Pending Requests</div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link
            :to="{
              name: 'filtered_specialist_requests',
              params: { status: 'completed' },
            }"
          >
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ completedRequests.length }}
                </div>
              </div>
              <div class="card-footer ls-center">Completed Requests</div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link
            :to="{
              name: 'filtered_specialist_requests',
              params: { status: 'in-progress' },
            }"
          >
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ inProgressRequests.length }}
                </div>
              </div>
              <div class="card-footer ls-center">In Progress</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseSpecialist",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getRunnersDetails();
  },
  data() {
    return {
      runnerCategories: [],
      myRequests: [],
      pendingRequests: [],
      completedRequests: [],
      inProgressRequests: [],
    };
  },
  methods: {
    getRunnersDetails() {
      axios.get("v1/getrunner/get-runners").then((response) => {
        //console.log("Get Runner Requests : " + JSON.stringify(response.data.data));
        this.myRequests = response.data.data;
        for (var i = 0; i < this.myRequests.length; i++) {
          switch (this.myRequests[i].status) {
            case "pending":
              this.pendingRequests.push(this.myRequests[i]);
              break;
            case "completed":
              this.completedRequests.push(this.myRequests[i]);
              break;
            case "in progress":
              this.inProgressRequests.push(this.myRequests[i]);
              break;
          }
        }
        // this.pendingRequests = response.data.data.length;
      });
      axios.get("v1/getrunner/runner-categories").then((response) => {
        //console.log("Get Runner Cat : " + JSON.stringify(response.data));
        this.runnerCategories = response.data;
      });
    },
  },
};
</script>

<style scoped></style>
