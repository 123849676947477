var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ls-page"},[_vm._m(0),_c('div',{staticClass:"ps-section__content ls-page-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3 col-md-2 col-12"}),_c('div',{staticClass:"col-xl-8 col-lg-6 col-md-8 col-12"},[_c('router-view')],1),_c('div',{staticClass:"col-xl-2 col-lg-3 col-md-2 col-12"})]),_c('hr'),(_vm.auth.isAuthenticated)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('router-link',{attrs:{"to":{
            name: 'filtered_specialist_requests',
            params: { status: 'pending' },
          }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.pendingRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("Pending Requests")])])])],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('router-link',{attrs:{"to":{
            name: 'filtered_specialist_requests',
            params: { status: 'completed' },
          }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.completedRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("Completed Requests")])])])],1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('router-link',{attrs:{"to":{
            name: 'filtered_specialist_requests',
            params: { status: 'in-progress' },
          }}},[_c('div',{staticClass:"card ls-admin-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text ls-center"},[_vm._v(" "+_vm._s(_vm.inProgressRequests.length)+" ")])]),_c('div',{staticClass:"card-footer ls-center"},[_vm._v("In Progress")])])])],1)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps-section__header ls-page-header"},[_c('h1',{staticClass:"container ls-page-header-title"},[_vm._v("Request a Specialist")]),_c('h3',{staticClass:"container ls-page-header-subtitle"},[_vm._v(" For those jobs in need of a carefully selected, verified Specialist. Our team of specialists are available to give their expert services at great value. ")])])
}]

export { render, staticRenderFns }